import "./src/fonts.css";
import "./src/utils/sentry.js";
import React from 'react'
import { StyleSheetManager } from 'styled-components'

export const wrapRootElement = ({ element }) => {
    return <StyleSheetManager>{element}</StyleSheetManager>
}

// export const onClientEntry = () => {
//     window.addEventListener('load', () => {
//         console.group('GTM Debug')
//         console.log('GTM Loaded:', window.google_tag_manager)
//         console.log('DataLayer:', window.dataLayer)
//         console.groupEnd()

//         // Test event
//         window.dataLayer.push({
//             event: 'test_event',
//             eventCategory: 'Test',
//             eventAction: 'init',
//             eventLabel: 'GTM Test'
//         })
//     })
// }
